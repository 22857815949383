import { render, staticRenderFns } from "./FormLookup.vue?vue&type=template&id=1885e96a&scoped=true&"
import script from "./FormLookup.js?vue&type=script&lang=js&"
export * from "./FormLookup.js?vue&type=script&lang=js&"
import style0 from "./FormLookup.css?vue&type=style&index=0&id=1885e96a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1885e96a",
  null
  
)

export default component.exports