import _ from 'lodash';

export default {
  name: 'form-lookup',
  props: [
    'value',
    'label',
    'rules',
    'required',
    'store',
    'itemKey',
    'itemValue',
    'filter',
    'customProps',
    'sort',
    'afterFetchFilter',
  ],

  data() {
    return {
      errors: [],
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    items() {
      let store = null;

      if (this.store) store = this.$store.state[this.store];
      if (!store || !store.elements) return [];

      let items = [...store.elements];
      if (this.sort && typeof this.sort === 'function') {
        items.sort(this.sort);
      }
      if (this.afterFetchFilter && typeof this.afterFetchFilter === 'function') {
        items = this.afterFetchFilter(items);
      }
      return items;
    },
    indicator() {
      return this.itemKey || 'key';
    },
    text() {
      return this.itemValue || this.itemKey || 'key';
    },
  },
  watch: {
    filter() {
      this.fetch();
    },
  },
  methods: {
    async fetch() {
      if (!(this.store && this.$store.state[this.store])) return;

      await this.$store.dispatch(`${this.store}/find`, {
        filter: this.filter || {},
      });

      if (this.rules && this.rules.length) this.validate(this.rules, this.value);
    },
    onEdit(val) {
      if (this.rules && this.rules.length) this.validate(this.rules, val);
      this.$emit('input', val);
    },

    async validate(rules, value) {
      this.errors = [];

      await Promise.all(
        _.map(rules, async validator => {
          const result = await validator(value);
          if (result !== true) this.errors.push(result);
        })
      );

      const isValid = this.errors.length === 0;
      this.$emit('valid', isValid);
    },
  },
};
